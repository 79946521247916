<template>
  <ValidationProvider
    :rules="validationRules"
    class="question single-answer column"
    :class="width"
    tag="div"
    v-slot="{ errors }"
    v-if="!reviewMode"
  >
    <div class="question-text has-text-weight-bold">
      <p class="question-text__code" v-if="showQuestionCode">
        {{ question.code }}
      </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content">
      <template v-if="categories">
        <b-field>
          <b-input
            v-model="value[`${question.qid}`]"
            class="is-hidden"
          ></b-input>
          <b-autocomplete
            @keydown.native="keyHandler"
            class="is-borderless"
            :field="`answer.${lang}`"
            :data="categories"
            :placeholder="$t('dropdown.placeholder')"
            group-field="title"
            group-options="answers"
            icon-right="chevron-down"
            v-model="keyword"
            expanded
            open-on-focus
            :value="value[`${question.qid}`]"
            clearable
            @select="onChange"
          >
            <template #empty>{{ $t("dropdown.empty_result") }}</template>
            <template #group="group">
              <span v-if="categories[group.index].display_title && categories[group.index].answers.length > 0" class="has-text-weight-bold"> {{  group.group[lang] || Object.values(group.group)[0] }} </span>
              <hr v-else-if="categories[group.index].answers.length > 0" class="my-1"/>
            </template>
          </b-autocomplete>
        </b-field>
      </template>
      <template v-else>
        <b-field>
          <b-input
            v-model="value[`${question.qid}`]"
            class="is-hidden"
          ></b-input>
          <b-autocomplete
            @keydown.native="keyHandler"
            class="is-borderless"
            :field="`answer.${lang}`"
            :data="filteredDataObj"
            :placeholder="$t('dropdown.placeholder')"
            icon-right="chevron-down"
            v-model="keyword"
            expanded
            open-on-focus
            :value="value[`${question.qid}`]"
            clearable
            @select="onChange"
          >
            <template #empty>{{ $t("dropdown.empty_result") }}</template>
          </b-autocomplete>
        </b-field>
      </template>
    </div>
  </ValidationProvider>
  <div class="question" v-else>
    <div class="is-flex is-align-items-center">
      <div class="question-text has-text-weight-bold column p-0">
        <p class="question-text__code" v-if="showQuestionCode">
          {{ question.code }}
        </p>
        <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      </div>
      <b-button type="is-text" @click="$emit('edit')">
        <b-icon icon="pencil" type="is-primary"></b-icon>
      </b-button>
    </div>
    <div class="question-content">
      <template v-if="categories">
        <div :key="index" v-for="(category, index) in categories">
          <p v-if="category.display_title && showCategory(category.answers)" class="category-title">
            {{ category.title[lang] || Object.values(category.title)[0] }}
          </p>
          <template v-for="answer in category.answers">
            <div
              v-if="value[`${question.qid}`] === answer.code"
              :key="answer.id"
              class="answer"
            >
              <span class="option" v-html="answer.answer[lang]"></span>
            </div>
          </template>
        </div>
      </template>
      <template v-else v-for="answer in question.answers">
        <div
          v-if="value[`${question.qid}`] === answer.code"
          :key="answer.id"
          class="answer"
        >
          <span class="option" v-html="answer.answer[lang]"></span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { QuestionMixin } from "@/mixins/question";
import { UnFocusMixin } from "@/mixins/unfocus";
import { BlockRandomizationMixin } from "@/mixins/block-randomization";
export default {
  name: "DropdownList",
  mixins: [QuestionMixin, UnFocusMixin, BlockRandomizationMixin],
  data() {
    return {
      keyword: "",
    };
  },
  methods: {
    async keyHandler(e){
      if(e.key === 'Enter'){
        await this.$emit("is-enter");
      }
    },
    async onChange(option) {
      await this.$emit("input", {
        [this.question.qid]: option?.code,
      });
      await this.$emit("move-next");
    },
    showCategory(answers) {
      return answers.some(answer => this.value[`${this.question.qid}`] === answer.code)
    }
  },
  computed: {
    filteredDataObj() {
      return this.question.answers.filter((option) => {
        return (
          option.answer[this.lang]
            .toString()
            .toLowerCase()
            .indexOf(this.keyword.toLowerCase()) >= 0
        );
      });
    },
    customValidationRules() {
      return {
        mandatory: {
          type: "DL",
          value: this.value[`${this.question.qid}`],
        },
      };
    },
  },
  created() {
    setTimeout(() => {
      if (!this.value[this.question.qid]) {
        this.$emit("input", { [this.question.qid]: "" });
      }
      const selectedOption = this.question.answers.find(
        (option) => option.code === this.value[this.question.qid]
      );
      if (selectedOption) {
        this.keyword = selectedOption.answer[this.lang];
        this.unFocus();
      }
    }, 300);
  },
};
</script>
<style lang="scss" scoped>
.card {
  overflow: unset;
}
</style>
