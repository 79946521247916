export const UnFocusMixin = {
  methods: {
    unFocus() {
      window.focus();
      if (document.activeElement) {
        document.activeElement.blur();
      }
      document.getElementsByTagName("BODY")[0].click();
    },
  },
};
